.s-landingGates {
  padding: 3em 4.5em;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__row {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
    &:first-child {
      min-height: 20em;
      height: 66%;
    }
    &:last-child {
      min-height: 14.5rem;
      height: 33%;
    }
    @media (max-width: 32em) {
      &:last-child {
        height: 100%;
      }
    }
    @include media-lg {
      flex-direction: row;
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
  &__col {
    background: #3b444c;
    border-radius: 2px;
    align-items: flex-end;
    // min-height: 20em;
    margin-bottom: 1em;
    padding-left: 1.5em;
    transition: transform 0.25s;
    display: flex;
    position: relative;
    overflow: hidden;
    @include media-lg {
      margin-bottom: 0;
      margin-right: 1em;
    }
    &:last-child {
      margin-bottom: 0;
      @include media-lg {
        margin-right: 0;
      }
    }

    &__title {
      margin: 0;
      color: tomato;
      z-index: 100;
      color: white;
      margin-bottom: 1.5rem;
      font-size: 1.75em;
      line-height: 1.154;
    }
    &__bg {
      position: absolute;
      max-width: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.05);
      transition: transform 250ms;
      transition-timing-function: ease-out;
      background-size: cover;
    }
    &:hover {
      .s-landingGates__col__bg {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &--disabled {
      &:hover {
        .s-landingGates__col__bg {
          transform: none;
        }
        cursor: default;
      }
      background: linear-gradient(25deg, rgb(56, 56, 56) 0%, rgb(135, 135, 135) 100%);
      .s-landingGates__col__title {
      }
    }
  }
  @media (max-width: 32em) {
    .l-1\/2,
    .l-1\/3 {
      width: 100%;
    }
    overflow: scroll;
    display: block;
  }
}
